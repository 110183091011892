import axios from 'services/axios';

export const getToken = async (id) => {
  const { data } = await axios.post(
    `https://auth.s1.sceenic.co/token/v2/`,
    {
      room_name: id,
    },
    {
      headers: {
        'auth-token': process.env.REACT_APP_TOKEN,
      },
    },
  );

  return data.streaming_token;
};
