import axios from 'services/axios';

export const getToken = async (userId) => {
  const requestData = {
    user_id: userId,
  };
  if (process.env.REACT_APP_PUBLIC_CHAT_HOST) {
    requestData.host = process.env.REACT_APP_PUBLIC_CHAT_HOST;
  }
  const { data } = await axios.post(`${process.env.REACT_APP_PUBLIC_CHAT_API_ENDPOINT}`, requestData);

  return data.token;
};
