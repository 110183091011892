import './Polls.css';
import { useCallback, useEffect, useState } from 'react';
import PChat from '../../services/PChat';

function Polls() {
  const [poll, setPoll] = useState({ question: '', options: [] });
  const [isPollVisible, setIsPollVisible] = useState(false);
  const [isVoted, setIsVoted] = useState(false);

  const calculatePercentage = (currentPoll) => {
    const totalVotes = currentPoll.options.reduce((acc, option) => acc + option.counter, 0);
    const updatedOptions = currentPoll.options.map((option) => {
      return { ...option, percentage: ((option.counter / totalVotes) * 100).toFixed(2) };
    });
    setPoll({ question: currentPoll.question, options: updatedOptions });
  };

  const updateOption = useCallback((option) => {
    setPoll((prevPoll) => {
      prevPoll.options = prevPoll.options.map((o) => {
        if (o.id === option.id) {
          return { ...o, counter: option.counter };
        }
        return o;
      });
      calculatePercentage(prevPoll);
      return prevPoll;
    });
  }, []);

  const showActivePollIfExists = useCallback(async () => {
    const activePoll = await PChat.getPoll();
    if (activePoll.polls.length) {
      const currentPoll = activePoll.polls[0];
      setPoll({ question: currentPoll.question, options: currentPoll.options });
      setIsPollVisible(true);
    }
    if (activePoll.existsVotes.length) {
      setIsVoted(true);
      calculatePercentage(activePoll.polls[0]);
    }
  }, []);

  useEffect(() => {
    PChat.on(PChat.Events.POLL, (pollData) => {
      console.log('pollData', pollData);
      if (pollData.is_active) {
        setPoll({ question: pollData.question, options: pollData.options });
        setIsPollVisible(true);
        setIsVoted(false);
      } else {
        setIsPollVisible(false);
        setIsVoted(false);
        setPoll({ question: '', options: [] });
      }
    });

    if (PChat.state === PChat.States.CONNECTED) {
      showActivePollIfExists();
    } else {
      PChat.on(PChat.Events.CONNECT, showActivePollIfExists);
    }
    PChat.on(PChat.Events.VOTE, (option) => {
      updateOption(option);
    });

    return () => {
      PChat.off(PChat.Events.POLL);
      PChat.off(PChat.Events.CONNECT);
      PChat.off(PChat.Events.VOTE);
    };
  }, [updateOption, showActivePollIfExists]);

  const toggleChat = () => {
    setIsPollVisible(!isPollVisible);
  };

  const handleClick = (option) => {
    return async () => {
      const pollResult = await PChat.votePoll(option.poll_id, option.id);
      setIsVoted(true);
      updateOption(pollResult);
    };
  };

  return (
    <>
      <div className={`poll-container ${isPollVisible ? 'visible' : ''}`}>
        <h5>{poll.question}</h5>
        <div className="poll-options">
          {!isVoted &&
            poll.options &&
            poll.options.map((option, index) => (
              <div className="option" key={index} onClick={handleClick(option)}>
                {option.data.text}
              </div>
            ))}
          {isVoted &&
            poll.options &&
            poll.options.map((option, index) => (
              <div
                className="option"
                key={index}
                style={{
                  background: `linear-gradient(
      to right,
      rgba(0, 123, 255, 0.8) 0%,
      rgba(0, 123, 255, 0.8) ${option.percentage}%,
      rgba(255, 255, 255, 0.1) ${option.percentage}%,
      rgba(255, 255, 255, 0.1) 100%
    )`,
                }}
              >
                {option.data.text} - {option.counter} votes ({option.percentage}%)
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Polls;
