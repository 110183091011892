import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setJoinModalType} from "store/reducers/SessionReducer";

import './WatchParty.css';

const WatchParty = ({ typeJoin }) => {
  const dispatch = useDispatch();

  async function handleClick() {
    dispatch(setJoinModalType('USER_DATA_ENTRY'));
  }

  if(typeJoin) {
    return (
      <a className="watch-party-type-join" onClick={handleClick} href='javascript:void(0);'>
        Join
      </a>
    );
  }

  return (
    <button className="button watch-party-btn" onClick={handleClick} title="Watch party">
      <svg xmlns="http://www.w3.org/2000/svg" fill="#cbcbcb" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="QueuePlayNextIcon"><path d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h2v-2H3V5h18v8h2V5c0-1.11-.9-2-2-2m-8 7V7h-2v3H8v2h3v3h2v-3h3v-2zm11 8-4.5 4.5L18 21l3-3-3-3 1.5-1.5z"></path></svg>
    </button>
  );
};

export default WatchParty;
