import { useEffect, useRef, useState } from 'react';
import PChat from '../../services/PChat';
import './Chat.css';

function Chat({ userId }) {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState(''); // New state variable for the input value
  const messagesRef = useRef(null);

  useEffect(() => {
    if (PChat._cache['chat_history']) {
      setMessages(PChat._cache['chat_history']);

      setTimeout(() => {
        document
          .getElementsByClassName('message')
          [document.getElementsByClassName('message').length - 1].scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
      }, 10);
    }

    PChat.on(PChat.Events.MESSAGE, (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      messagesRef.current.scrollIntoView({ behavior: 'smooth' });
    });

    return () => {
      PChat.off(PChat.Events.MESSAGE);
      PChat.off(PChat.Events.HISTORY_LOAD);
    };
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      PChat.publish(inputValue);
      setInputValue('');
    }
  };

  return (
    <>
      <div className={`chat-container`}>
        <h5>Chat Room</h5>

        <div className="messages" ref={messagesRef}>
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.user.meta.username === userId ? 'self' : ''}`}>
              <div className="message-username">{message.user.meta.username}:</div>
              <div className="message-content">{message.content}</div>
            </div>
          ))}
        </div>
        <input
          className="chat-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          placeholder="Type your message here..."
        />
      </div>
    </>
  );
}

export default Chat;
