import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import { useEffect } from 'react';

function AdminPage() {
  useEffect(() => {
    const editor = grapesjs.init({
      container: '#gjs',
      components:
        '<div data-gjs-editable="false" data-gjs-removable="false"><video data-gjs-editable="false" data-gjs-removable="false" data-gjs-draggable="false" src="https://dev-stand.ru/media/video.mp4" /></div>',
      style: '.gjs-component {color: red}',
      storageManager: false,
      blockManager: {
        appendTo: '#blocks',
        blocks: [
          {
            id: 'image',
            label: 'Image',
            media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
        </svg>`,
            // Use `image` component
            content: { type: 'image' },
            // The component `image` is activatable (shows the Asset Manager).
            // We want to activate it once dropped in the canvas.
            activate: true,
            // select: true, // Default with `activate: true`
          },
        ],
      },
      // panels: { defaults: [] },
    });
  }, []);

  return (
    <div>
      <h1>Edit CWP template</h1>
      <div id="gjs"></div>
      <div id="blocks"></div>
    </div>
  );
}

export default AdminPage;
