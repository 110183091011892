import { PChatSDK } from '@sscale/pchatsdk';
import { getToken } from './chat';

const PChat = new PChatSDK();
PChat._cache = {};

PChat.on(PChat.Events.HISTORY_LOAD, (messages) => {
  PChat._cache['chat_history'] = messages;
});

PChat.on(PChat.Events.REACTIONS_STATS, (stats) => {
  PChat._cache['reaction_stats'] = stats;
});

PChat.on(PChat.Events.CONNECT, async () => {
  try {
    await PChat.getReactionStats();
    await PChat.loadOlder();
  } catch (e) {
    console.log(e);
  }
});

PChat.init = async (room, userId) => {
  if (PChat.state !== PChat.States.CONNECTED) {
    let authToken = process.env.REACT_APP_CHAT_TOKEN || '';
    if (!authToken) {
      authToken = await getToken(room);
    }
    await PChat.connect(room, userId, authToken);
  }
};

export default PChat;
