import Polls from '../Polls/Polls';
import './InteractivityLayer.css';
import Reactions from '../Reactions/Reactions';
import { useState } from 'react';

function InteractivityLayer({ userId, roomId }) {
  const [flyingReactions, setFlyingReactions] = useState([]);
  return (
    <div className="interactivity-layer-container">
      <Reactions setFlyingReactions={setFlyingReactions} userId={userId} roomId={roomId} />
      <Polls userId={userId} roomId={roomId} />
    </div>
  );
}

export default InteractivityLayer;
