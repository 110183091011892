import './Subscriber.css';
import React, { useEffect, useState } from 'react';
import { Subscriber, SubscriberListener, ErrorListener, StatsListener } from '@sscale/cdnwebsdk';
import { getSubscriberToken } from '../../services/cdn';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Session from '../Session/Session';
import PChat from '../../services/PChat';
import room from '../Celebrity/components/Rooms/Room/Room';
import Chat from '../Chat/Chat';
import InteractivityLayer from '../InteractivityLayer/InteractivityLayer';

const urlParams = new URLSearchParams(window.location.search);
const roomId = urlParams.get('streamRoom') || 'wroom';

function SubscriberPage({ userId }) {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isInSession, setIsInSession] = useState(false);

  useEffect(() => {
    const startSubscription = async () => {
      try {
        const token = await getSubscriberToken(roomId);
        const url = 'https://cdn.management.sceenic.co/v1/stream';
        const subscriber = new Subscriber(url, token);
        subscriber.subscribe();
        subscriber.startCollectingStats();
        const stream = new MediaStream();

        SubscriberListener.on('subscribed', () => {
          setIsSubscribed(true);
          const videoElement = document.getElementById('subscriber');
          videoElement.srcObject = stream;
        });

        SubscriberListener.on('track', (track) => {
          stream.addTrack(track);
        });
        StatsListener.on('stats', (stats) => {
          if (stats) {
            const { e2eDelay } = stats;
            const latencyInfo = document.getElementById('latency-info');
            latencyInfo.innerText = `${e2eDelay}ms`;
          }
        });
      } catch (error) {
        toast('Error getting subscriber token', { type: 'error' });
        console.error('Error getting subscriber token', error);
      }
    };
    startSubscription().catch((error) => console.error('Error starting subscription', error));

    const errorHandler = (error) => {
      console.log(error.name);
      console.log(error.code);
      console.log(error.message);
      console.log(error.originalMessage);

      toast(`Error (${error.code}) ${error.message}`, {
        type: 'error',
      });
    };
    ErrorListener.onError(errorHandler);
  }, []);

  const joinCelebrityHandler = () => {
    setIsInSession(true);
  };

  return (
    <>
      {isInSession && <Session userId={userId} autoJoin={true} />}
      {!isInSession && (
        <div className="session-container subscriber-page">
          <div className="s-body">
            <div className="s-activity-container">
              <div className="s-player-container">
                {isSubscribed && (
                  <>
                    <div className="subscriber-video-wrapper">
                      <video
                        className="subscriber-video"
                        id="subscriber"
                        playsInline
                        autoPlay
                        disablePictureInPicture
                      />
                      <button className="button join-celebrity" onClick={joinCelebrityHandler}>
                        Join Celebrity
                      </button>
                      <div className="latency-info">
                        <span>Latency: </span>
                        <span id="latency-info"></span>
                      </div>
                      <InteractivityLayer userId={userId} roomId={roomId} />
                    </div>
                  </>
                )}
                {!isSubscribed && (
                  <>
                    <div className="subscriber-video-placeholder">Waiting for broadcaster to start streaming</div>
                    <div className="player-container">
                      <div className="subscriber-video-preview">
                        <div className="loader" />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default SubscriberPage;
