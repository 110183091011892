import React, { useCallback, useEffect, useState } from 'react';
import './Reactions.css';
import PChat from '../../services/PChat';

function Reactions({ roomId, userId }) {
  const [reactionsStats, setReactionsStats] = useState([]);
  const reactions = ['👍', '❤️', '🔥', '😀', '😂', '😍', '😢', '👎']; // Add your reactions here

  const reactionData = {};
  reactionsStats.forEach((reaction) => {
    reactionData[reaction._id] = reaction.count;
  });
  function handleReactionClick(reaction) {
    PChat.sendReaction(reaction);
  }

  const handleReaction = useCallback((reaction) => {
    let flyingReactionsContainer = document.getElementById('flying-reactions');
    if (!flyingReactionsContainer) {
      flyingReactionsContainer = document.createElement('div');
      flyingReactionsContainer.id = 'flying-reactions';
      flyingReactionsContainer.className = 'flying-reactions';
      const playerContainer = document.getElementsByClassName('player-container');
      playerContainer[0].appendChild(flyingReactionsContainer);
    }
    const flyingReaction = document.createElement('span');
    flyingReaction.className = 'flying-reaction';
    flyingReaction.innerText = reaction;
    flyingReactionsContainer.appendChild(flyingReaction);

    setTimeout(() => {
      flyingReactionsContainer.removeChild(flyingReaction);
    }, 5000);
  }, []);

  useEffect(() => {
    PChat.on(PChat.Events.REACTION, (reaction) => {
      handleReaction(reaction.reaction);
      setReactionsStats((prevStats) => {
        const isReactionAlreadyCounted = prevStats.some((stat) => stat._id === reaction.reaction);
        const updatedStats = prevStats.map((stat) => {
          if (stat._id === reaction.reaction) {
            return { ...stat, count: stat.count + 1 };
          }
          return stat;
        });
        if (!isReactionAlreadyCounted) {
          updatedStats.push({ _id: reaction.reaction, count: 1 });
        }
        return updatedStats;
      });
    });

    PChat.on(PChat.Events.REACTIONS_STATS, (stats) => {
      setReactionsStats(stats);
    });

    if (PChat._cache['reaction_stats']) {
      setReactionsStats(PChat._cache['reaction_stats']);
    }

    return () => {
      PChat.off(PChat.Events.REACTION);
      PChat.off(PChat.Events.REACTIONS_STATS);
      PChat.off(PChat.Events.CONNECT);
    };
  }, [handleReaction, userId, roomId]);

  return (
    <div className={`reactions-container`}>
      {reactions.map((reaction, index) => (
        <div className="reaction-wrapper" key={index}>
          <span className="reaction" onClick={() => handleReactionClick(reaction)}>
            {reaction}
          </span>
          <span className="counter">{reactionData[reaction] || 0}</span>
        </div>
      ))}
    </div>
  );
}

export default Reactions;
